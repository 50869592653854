import { gtag, install } from "ga-gtag";
import ConfigData from "../../services/config.json";

install(ConfigData.Google.Tag_ID);

const GACustomEventTrack = (event_name, action) => {
  gtag("event", event_name, {
    event_category: action,
  });
};

const GACustomPageView = (event_name, title) => {
  gtag("event", event_name, {
    title: title,
  });
};

const GACustomSourcePage = (
  event_name,
  event_category,
  page_location,
  title
) => {
  gtag("event", event_name, {
    event_category: event_category,
    event_label: event_name,
    page_location: page_location,
    page_title: title,
  });
};

const GAPurchaseCustomEvent = (value, tax, currency, coupon) => {
  gtag("event", "purchase", {
    transaction_id: Math.random() * (1000000 - 1) + 1,
    value: value,
    tax: tax,
    shipping: 0,
    currency: currency,
    coupon: coupon,
  });
};

export {
  GACustomEventTrack,
  GACustomPageView,
  GACustomSourcePage,
  GAPurchaseCustomEvent,
};
