import HttpService from "../shared/http-service";
import configData from "../config.json";
const URL = configData.AwisAPI.BaseAddress;

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
  },
};

const storeTokenInfo = async (data) => {
  sessionStorage.removeItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  sessionStorage.setItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info",
    JSON.stringify(data)
  );
  return true;
};

const getTokenInfo = () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    return JSON.parse(data);
  }
  return null;
};

const getCultureCode = () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    return JSON.parse(data).cultureCode?.toLowerCase();
  }
  return null;
};

const getIsCompany = () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    return JSON.parse(data).isCompany;
  }
  return null;
};

const getLoginFlow = () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    return JSON.parse(data).loginflow;
  }
  return null;
};

const getLangCode = () => {
  var cultureCode = getCultureCode();
  if (cultureCode != null) {
    return configData.CultureCodeMap[cultureCode].code;
  }
  return null;
};

const matchCultureAndRedirectURL = () => {
  var pathName = window.location.pathname;
  var searchVals = window.location.search;
  var localCode = getLangCode();

  if (isAuthorized() && !IsUrlMatchWithCultureCode()) {
    //URL is not match with culture code
    if (getCultureCode() == configData.DefaultCulture) {
      //if default culture, then remove culture from url
      pathName = pathNameWithoutCulture();
    } else if (localCode != null) {
      //append or modify the culture of url
      pathName = "/" + localCode + pathNameWithoutCulture();
    }
    window.location.replace(pathName + searchVals);
  }
};

const pathNameWithoutCulture = () => {
  var pathName = window.location.pathname;
  var pathNameWithoutCulture = pathName;
  var urlSegments = pathName.split("/");
  var langCodeFromURL = "";
  var localCode = getLangCode();

  if (localCode != null && urlSegments.length >= 2) {
    langCodeFromURL = urlSegments[1];

    if (configData.TwoLetterCodes.indexOf(langCodeFromURL) > -1) {
      //there is a match with 2 letter codes (2 letter code exist)
      pathNameWithoutCulture = pathName.substring(3, pathName.length);
    }
    //else no culture code associate with url, then we can use same url
  }

  return pathNameWithoutCulture;
};

const IsUrlMatchWithCultureCode = () => {
  var pathName = window.location.pathname;
  var urlSegments = pathName.split("/");
  var langCodeFromURL = "";
  var localCode = getLangCode();

  if (localCode != null && urlSegments.length >= 2) {
    langCodeFromURL = urlSegments[1];

    if (configData.TwoLetterCodes.indexOf(langCodeFromURL) > -1) {
      //there is a match with 2 letter codes (2 letter code exist)
      return langCodeFromURL == localCode;
    } else {
      if (configData.DefaultCulture.includes(getCultureCode())) {
        //for default culture, no culture code in URL
        return true;
      } else {
        //not default culture and no culture code in url; it is a mismatch
        return false;
      }
    }
  }
  return false;
};

const getCurrencyCode = (cultureCode) => {
  if (cultureCode != null) {
    return configData.CultureCodeMap[cultureCode.toLowerCase()].currency;
  }
  return null;
};

const getDibsImageNets = () => {
  var cultureCode = getCultureCode();
  if (cultureCode != null) {
    return configData.CultureCodeMap[cultureCode].dibsImageNets;
  }
  return null;
};

const getDibsImageVisa = () => {
  var cultureCode = getCultureCode();
  if (cultureCode != null) {
    return configData.CultureCodeMap[cultureCode].dibsImageVisa;
  }
  return null;
};

const getPaymentUpdateImage = () => {
  var cultureCode = getCultureCode();
  if (cultureCode != null) {
    return configData.CultureCodeMap[cultureCode].updateCardPayment;
  }
  return null;
};

const getAccessToken = async () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    var tempData = JSON.parse(data);
    return tempData.access_token;
  }
  return null;
};

const isAuthorized = () => {
  var data = sessionStorage.getItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
  if (data != null) {
    var tempData = JSON.parse(data);
    if (new Date(tempData[".expires"]).getTime() < new Date().getTime()) {
      signOut();
      return false;
    } else {
      return true;
    }
  }
  return false;
};

const signOut = () => {
  sessionStorage.removeItem(
    "1986authdata19901985dataprocess_autoconcept_vo_access_token_info"
  );
};

const clearSessionData = () => {
  sessionStorage.removeItem("vo-info");
  sessionStorage.removeItem("currentCoverage");
  sessionStorage.removeItem("vo-details");
  sessionStorage.removeItem("DibsData");
  sessionStorage.removeItem("autoconcept_vo_products");
  sessionStorage.removeItem("vo_product");
};

const login = async (data) => {
  const params = new URLSearchParams();
  params.append("username", data.username);
  params.append("password", data.password);
  params.append("grant_type", data.grant_type);
  params.append("client_id", data.client_id);
  params.append("culture_code", data.culture_code);
  params.append("user_type", data.user_type);
  return HttpService.postLoginAsync(URL + "Token", params, config);
};

const loginNewCustomer = async (data, isnewuser) => {
  const params = new URLSearchParams();
  params.append("username", data.username);
  params.append("password", data.password);
  params.append("grant_type", data.grant_type);
  params.append("client_id", data.client_id);
  params.append("culture_code", data.culture_code);
  params.append("user_type", data.user_type);
  if (isnewuser) {
    params.append("is_otp_user", true);
  }
  return HttpService.postLoginAsync(URL + "Token", params, config);
};

const resetPassword = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteAuth/ResetLogin", data);
};

const ChangePassword = async (data) => {
  return HttpService.postAsync(
    URL + "api/WebsiteAuth/ChangePasswordRequest",
    data
  );
};

const AcceptPolicy = async () => {
  return HttpService.postAsync(
    URL + "api/WebsiteAuth/UpdatePolicyAccepted?status=" + true
  );
};

const resendOtpCode = async (data) => {
  return HttpService.postAsync(URL + "api/WebsiteAuth/ResendOtpCode", data);
};

const UpdateNewPassword = async (data, token) => {
  const config = {
    headers: {
      Authorization: "bearer " + token,
    },
  };
  return HttpService.postLoginAsync(
    URL + "api/WebsiteAuth/UpdatePassword",
    data,
    config
  );
};

export default {
  login,
  getIsCompany,
  getLoginFlow,
  getTokenInfo,
  getAccessToken,
  storeTokenInfo,
  isAuthorized,
  signOut,
  resetPassword,
  AcceptPolicy,
  ChangePassword,
  getCultureCode,
  getLangCode,
  matchCultureAndRedirectURL,
  IsUrlMatchWithCultureCode,
  getCurrencyCode,
  getDibsImageNets,
  getDibsImageVisa,
  getPaymentUpdateImage,
  loginNewCustomer,
  resendOtpCode,
  clearSessionData,
  UpdateNewPassword,
};
