import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import Loading from "../../shared-components/loading/Loading";
//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";
import { useTranslation } from "react-i18next";

//services
import UserService from "../../../../services/vo/user-service";
import AuthService from "../../../../services/vo/auth-service";
import EncryptionService from "../../../../services/vo/encryption-service";

//utils
import AuthUtils from "../../../../utils/auth-utils/AuthUtils";

const OwnerDetailsPanal = forwardRef((props, ref) => {
  const pid_Regex =
    /((1[9]|(2[0-9]))[0-9]{2}((((0[13578])|(1[02]))(([0-2][0-9])|(3[01])))|(((0[469])|(11))(([0-2][0-9])|(30)))|(02[0-2][0-9])))[-][0-9]{4}$/;

  const {
    SubmitDetails,
    SetInformationCompleted,
    isNewCustomerFlow,
    voDetails,
    isVoDetailsChecked,
  } = props;
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [loading, SetLoading] = useState(false);
  const [validationPID, SetValidatePID] = useState(false);
  const [revertButtonVisible, setRevertButtonVisible] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [revertButtonTooltipVisible, setRevertButtonTooltipVisible] =
    useState(false);
  const [emailValidationError, setEmailValidationError] = useState(null);
  const [currentProfileData, SetCurrentProfileData] = useState(null);
  const [isEmailEditable, SetIsEmailEditable] = useState(false);
  const [isToolTipVisible, SetIsToolTipVisible] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    reset,
    errors,
    trigger,
    formState,
  } = useForm({
    mode: "onBlur",
  });

  const wrapperRef = useRef(null);
  const emailTooltipWrapperRef = useRef(null);
  const { t } = useTranslation();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setRevertButtonTooltipVisible(false);
    }
    if (
      emailTooltipWrapperRef.current &&
      !emailTooltipWrapperRef.current.contains(event.target)
    ) {
      SetIsToolTipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    SetLoading(true);
    // const voDetails = JSON.parse(sessionStorage.getItem('vo-details'));
    if (!voDetails && isVoDetailsChecked) {
      if (
        !isNewCustomerFlow ||
        (isNewCustomerFlow && AuthService.isAuthorized())
      ) {
        getUserData();
      } else {
        SetLoading(false);
        // new Customer Flow
      }
      SetIsEmailEditable(!AuthUtils.CheckEmailFieldEditable());
    } else if (isVoDetailsChecked) {
      setCurrentEmail(
        isNewCustomerFlow ? voDetails.email : voDetails.currentEmail
      );
      SetCurrentProfileData(voDetails);
      reset(voDetails);
      CheckIsInformationUpdated(voDetails);
      //setRevertButtonVisible(true);
      SetLoading(false);

      if (showPersonalNumber()) {
        SetValidatePID(true);
      }
      SetIsEmailEditable(!AuthUtils.CheckEmailFieldEditable());
    }

    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [props.isVoDetailsChecked]);

  const CheckIsInformationUpdated = async (voDetails) => {
    if (AuthService.isAuthorized()) {
      const token = AuthService.getTokenInfo();
      const originalVoDetails = await (
        await UserService.getUserProfile(token?.userName)
      ).data.data;
      if (
        voDetails["address"] !== originalVoDetails["address"] ||
        voDetails["city"] !== originalVoDetails["city"] ||
        voDetails["email"] !== originalVoDetails["email"] ||
        (showPersonalNumber() &&
          !CheckIsCompany() &&
          voDetails["personalnumber"] !==
            originalVoDetails["orgOrSecurityNo"]) ||
        (!CheckIsCompany() &&
          voDetails["firstname"] !== originalVoDetails["firstName"]) ||
        voDetails["mobile_number"] !== originalVoDetails["mobile"] ||
        voDetails["postcode"] !== originalVoDetails["zipCode"] ||
        voDetails["surname"] !== originalVoDetails["lastName"]
      ) {
        setRevertButtonVisible(true);
      }
    } else {
      setRevertButtonVisible(true);
    }
  };

  useEffect(() => {
    if (!loading) {
      SetInformationCompleted(formState.isValid);
    }
  }, [formState]);

  useEffect(() => {
    if (validationPID && showPersonalNumber()) {
      onChangeFormValidation("personalnumber");
      SetValidatePID(false);
    }
  }, [validationPID]);

  const GetBillAddress = async (e) => {
    e.preventDefault();
    try {
      if (await trigger("personalnumber")) {
        const personalNumber = getValues("personalnumber");
        const info = await UserService.getBilVissionAddress(
          personalNumber.substring(2)
        );
        if (info.data) {
          reset({
            address: info.data.address,
            city: info.data.city,
            email: info.data.email,
            email_verified: false,
            personalnumber: personalNumber,
            firstname: info.data.firstName,
            mobile_number: info.data.teleMobile,
            policy_accepted: false,
            postcode: info.data.zipCode,
            surname: info.data.lastName,
          });
        }
        setRevertButtonVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      const token = AuthService.getTokenInfo();
      const value = await UserService.getUserProfile(token?.userName);
      setCurrentEmail(value.data.data.email);
      SetCurrentProfileData(value.data.data);
      reset({
        address: value.data.data.address,
        city: value.data.data.city,
        email: value.data.data.email,
        email_verified: false,
        personalnumber: value.data.data.orgOrSecurityNo,
        firstname: value.data.data.firstName,
        mobile_number: value.data.data.mobile,
        policy_accepted: false,
        postcode: value.data.data.zipCode,
        surname: value.data.data.lastName,
      });
      setRevertButtonVisible(false);
      SetLoading(false);
      if (showPersonalNumber()) {
        SetValidatePID(true);
      }
    } catch (error) {
      console.log(error);
      SetLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const formValues = getValues();
    const voDetails = {
      ...formValues,
      currentEmail: currentEmail,
    };
    var encryptedData = await EncryptionService.EncryptVoDetails(voDetails);
    const voDetailsEncrypted = {
      token: encryptedData.data.token,
      language: globalVariable?.moduleData?.language,
    };
    sessionStorage.removeItem("vo-details");
    sessionStorage.setItem("vo-details", JSON.stringify(voDetailsEncrypted));
    globalVariabledispatch("SET_VO_DETAILS", voDetails);
    SubmitDetails(voDetails);
  };
  useImperativeHandle(ref, () => ({
    SubmitDetails() {
      handleSubmit(onSubmit)();
    },
  }));

  const onInputChange = async (e) => {
    if (getValues("policy_accepted")) {
      setValue("policy_accepted", false, { shouldValidate: true });
    }
    setRevertButtonVisible(true);
  };

  const onEmailInputChange = async (e) => {
    if (getValues("email_verified") || getValues("policy_accepted")) {
      setValue("email_verified", false, { shouldValidate: true });
      setValue("policy_accepted", false, { shouldValidate: true });
    }
    setRevertButtonVisible(true);
  };

  const onRevertClick = (e) => {
    if (isNewCustomerFlow && !AuthService.isAuthorized()) {
      reset({});
      setRevertButtonVisible(false);
    } else {
      getUserData();
    }
  };

  const ValidateEmail = async (email) => {
    setEmailValidationError(null);
    if (currentEmail === email) {
      return true;
    } else {
      try {
        if (!isNewCustomerFlow) {
          const result = await UserService.ValidateEmail(email);
          if (result.data.statusCode == 2) {
            setEmailValidationError(
              globalVariable.moduleData
                ?.vo_information_form_email_validate_already_exist
            );
          } else if (result.data.statusCode == 3) {
            setEmailValidationError(
              t("vo_information_form_email_format_validate")
            );
          }
          if (result.data.isValid) {
            trigger("confirmEmail");
          }
          return result.data.isValid;
        } else {
          const result = await UserService.NewCustomerValidateEmail(email);
          if (result.data.statusCode == 2) {
            setEmailValidationError(
              t("vo_information_form_email_validate_already_exist")
            );
          } else if (result.data.statusCode == 3) {
            setEmailValidationError(
              t("vo_information_form_email_format_validate")
            );
          }
          if (result.data.isValid) {
            trigger("confirmEmail");
          }
          return result.data.isValid;
        }
      } catch (error) {
        setEmailValidationError(
          t("vo_information_form_email_validation_error")
        );
        console.log(error);
        return false;
      }
    }
  };

  const CheckIsCompany = () => {
    if (!isNewCustomerFlow) {
      return AuthService.getIsCompany() == "True";
    } else {
      return false;
    }
  };

  const showPersonalNumber = () => {
    if (!isNewCustomerFlow) {
      return AuthService.getCultureCode() == "sv-se";
    } else {
      if (globalVariable.moduleData?.language == "sv-SE") {
        return true;
      } else {
        return false;
      }
    }
  };

  const onChangeFormValidation = async (value) => {
    if (!loading) {
      await trigger(value);
    }
  };

  return (
    <div class="owner-details">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section class="main-page-title mt-20 mb-10 flex flex-end v-center mob-block">
              <h1 className="display-none">{t("vo_information_heading")}</h1>
              <div class="width-50 txt-right pr-40">
                {revertButtonVisible ? (
                  <div className="rever-btn-wrapper">
                    <a class="btn-back mt-10" onClick={() => onRevertClick()}>
                      {t("vo_information_form_revert")}
                    </a>
                    <span
                      className="info-icon"
                      ref={wrapperRef}
                      onClick={() =>
                        setRevertButtonTooltipVisible(
                          !revertButtonTooltipVisible
                        )
                      }
                    ></span>
                    {revertButtonTooltipVisible ? (
                      <div className="infomation-popup">
                        {t("vo_information_form_revert_tooltip")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </section>
            <section>
              <div>
                <div class="form-group">
                  {CheckIsCompany() ? (
                    ""
                  ) : (
                    <div className="input-group-pid">
                      {showPersonalNumber() && (
                        <div class="od-row flex column mb-0">
                          <label class="input-label mb-5">
                            {t("vo_information_form_personalnumber")}
                            <span className="required">*</span>
                          </label>
                          <div className="flex v-center mob-block personal-number">
                            <input
                              class="input-style-2 col per-number"
                              placeholder="YYYYMMDD-XXXX"
                              name="personalnumber"
                              onChange={onInputChange}
                              ref={register({
                                required: (
                                  <div class="form-validation mt-5">
                                    *{t("vo_information_form_required")}
                                  </div>
                                ),
                                pattern: {
                                  value: new RegExp(pid_Regex),
                                  message: (
                                    <div class="form-validation mt-5">
                                      *{t("vo_information_form_pn_validate")}
                                    </div>
                                  ),
                                },
                              })}
                            />
                            <a
                              class="btn-primary btn-get-info"
                              onClick={(e) => GetBillAddress(e)}
                              href="javascript:void(0)"
                            >
                              {t("vo_information_form_get_info")}
                            </a>
                          </div>

                          {errors.personalnumber &&
                            errors.personalnumber.message}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div class="form-group row-input">
                <div className="input-group mr-5">
                  {CheckIsCompany() ? (
                    <div class="od-row flex column mb-0">
                      <input
                        class="input-style-2"
                        name="surname"
                        placeholder="Surname"
                        onChange={onInputChange}
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("vo_information_form_required")}
                            </div>
                          ),
                        })}
                      />
                      <label class="input-label mb-5">
                        {
                          globalVariable.moduleData
                            ?.vo_information_form_companyName
                        }
                        <span className="required">*</span>
                      </label>
                      {errors.surname && errors.surname.message}
                    </div>
                  ) : (
                    <div class="od-row flex column mb-0">
                      <input
                        class="input-style-2"
                        name="firstname"
                        placeholder=" "
                        onChange={onInputChange}
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("vo_information_form_required")}
                            </div>
                          ),
                        })}
                      />
                      <label class="input-label mb-5">
                        {t("vo_information_form_firstname")}
                        <span className="required">*</span>
                      </label>
                      {errors.firstname && errors.firstname.message}
                    </div>
                  )}
                </div>
                <div className="input-group ml-5 ml-5">
                  {CheckIsCompany() ? (
                    ""
                  ) : (
                    <div class="od-row flex column mb-0">
                      <input
                        class="input-style-2"
                        name="surname"
                        placeholder=" "
                        onChange={onInputChange}
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("vo_information_form_required")}
                            </div>
                          ),
                        })}
                      />
                      <label class="input-label mb-5">
                        {t("vo_information_form_surname")}
                        <span className="required">*</span>
                      </label>
                      {errors.surname && errors.surname.message}
                    </div>
                  )}
                </div>
              </div>

              <div class="form-group">
                <div className="input-group mr-5">
                  <div class="od-row flex column mb-0">
                    <input
                      class="input-style-2"
                      name="address"
                      placeholder=" "
                      onChange={onInputChange}
                      ref={register({
                        required: (
                          <div class="form-validation mt-5">
                            *{t("vo_information_form_required")}
                          </div>
                        ),
                      })}
                    />
                    <label class="input-label mb-5">
                      {t("vo_information_form_address")}
                      <span className="required">*</span>
                    </label>
                    {errors.address && errors.address.message}
                  </div>
                </div>
                <div className="input-group ml-5">
                  <div class="od-row flex column mb-0">
                    <input
                      class="input-style-2"
                      name="postcode"
                      placeholder=" "
                      type="number"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      onChange={onInputChange}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      ref={register({
                        required: (
                          <div class="form-validation mt-5">
                            *{t("vo_information_form_required")}
                          </div>
                        ),
                      })}
                    />
                    <label class="input-label mb-5">
                      {t("vo_information_form_postcode")}
                      <span className="required">*</span>
                    </label>
                    {errors.postcode && errors.postcode.message}
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div className="input-group-city mr-5">
                  <div class="od-row flex column mb-0">
                    <input
                      class="input-style-2"
                      name="city"
                      placeholder=" "
                      onChange={onInputChange}
                      ref={register({
                        required: (
                          <div class="form-validation mt-5">
                            *{t("vo_information_form_required")}
                          </div>
                        ),
                      })}
                    />
                    <label class="input-label mb-5">
                      {t("vo_information_form_city")}
                      <span className="required">*</span>
                    </label>
                    {errors.city && errors.city.message}
                  </div>
                </div>
                <div className="input-group ml-5">
                  <div class="od-row flex column mb-0">
                    <input
                      class="input-style-2"
                      name="mobile_number"
                      placeholder=" "
                      onChange={onInputChange}
                      ref={register({
                        required: (
                          <div class="form-validation mt-5">
                            *{t("vo_information_form_required")}
                          </div>
                        ),
                        pattern: {
                          value: /^[-+]?\d*$/i,
                          message: (
                            <div class="form-validation mt-5">
                              *
                              {t(
                                "vo_information_form_mobileNo_format_validate"
                              )}
                            </div>
                          ),
                        },
                      })}
                    />
                    <label class="input-label mb-5">
                      {t("vo_information_form_mobilenumber")}
                      <span className="required">*</span>
                    </label>
                    {errors.mobile_number && errors.mobile_number.message}
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div className="input-group-email mr-5">
                  <div class="od-row flex column mb-0 mr-5">
                    <input
                      class="input-style-2"
                      name="email"
                      placeholder=" "
                      disabled={!isEmailEditable}
                      onChange={onEmailInputChange}
                      ref={register({
                        required: (
                          <div class="form-validation mt-5">
                            *{t("vo_information_form_required")}
                          </div>
                        ),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: (
                            <div class="form-validation mt-5">
                              *{t("vo_information_form_email_format_validate")}
                            </div>
                          ),
                        },
                        validate: (input) => ValidateEmail(input),
                      })}
                    />
                    {!isEmailEditable ? (
                      <label class="input-label mb-5">
                        {t("vo_information_form_email")}
                        <span className="required">*</span>
                        <span>
                          <span
                            className="email-tooltip-icon"
                            ref={emailTooltipWrapperRef}
                            onClick={() =>
                              SetIsToolTipVisible(!isToolTipVisible)
                            }
                          ></span>
                          {isToolTipVisible ? (
                            <div className="email-tooltip-box">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    "vo_information_form_email_dissabled_tooltip_text"
                                  ),
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      </label>
                    ) : (
                      <label class="input-label email-label mb-5">
                        {t("vo_information_form_email")}
                        <span className="required">*</span>
                      </label>
                    )}
                    {errors.email && errors.email.message}
                    {errors.email &&
                    !errors.email.message &&
                    emailValidationError != null ? (
                      <div class="form-validation mt-10">
                        *{emailValidationError}
                      </div>
                    ) : (
                      ""
                    )}
                    <p class="help-text-email">
                      {t("vo_information_form_email_instructions")}
                    </p>
                  </div>
                </div>
                {isEmailEditable ? (
                  <div className="input-group-city ml-5">
                    <div class="od-row flex column">
                      <input
                        class="input-style-2"
                        name="confirmEmail"
                        placeholder=" "
                        onChange={onInputChange}
                        ref={register({
                          required: (
                            <div class="form-validation mt-5">
                              *{t("vo_information_form_required")}
                            </div>
                          ),
                          validate: (value) =>
                            value.trim() === getValues("email") || (
                              <span class="form-validation mt-5">
                                {t(
                                  "vo_information_form_confirm_email_validate"
                                )}
                              </span>
                            ),
                        })}
                      />
                      <label class="input-label mb-5">
                        {t("vo_information_form_confirm_email")}
                        <span className="required">*</span>
                      </label>
                      {errors.confirmEmail?.message}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <br></br>
            </section>
            <section class="vo-checkbox-wrap">
              <div class="m-0">
                <div
                  className="display-none"
                  dangerouslySetInnerHTML={{
                    __html: t("vo_information_form_policy_text"),
                  }}
                />
              </div>
              <div class="flex v-center m-0">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    class="m-10"
                    name="policy_accepted"
                    onClick={() => onChangeFormValidation("policy_accepted")}
                    ref={register({
                      required: (
                        <div class="form-validation mt-5 ml-10">
                          {" "}
                          *{t("vo_information_form_required")}
                        </div>
                      ),
                    })}
                  />
                  <span class="checkmark"></span>
                </label>

                <p class="help-text-2">
                  {t("vo_information_form_policy_verify")}{" "}
                  <a
                    href={t("vo_information_form_policy_link")}
                    target="_blank"
                    class="link"
                  >
                    {t("vo_information_form_autoconcept_policy_intergrity")}
                  </a>
                  <span className="required">*</span>{" "}
                </p>
                {errors.policy_accepted && errors.policy_accepted.message}
              </div>
            </section>
          </form>
        </div>
      )}
    </div>
  );
});

export default OwnerDetailsPanal;
