import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../../shared-components/loading/Loading";
//global states
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//Services
import AuthService from "../../../../services/vo/auth-service";
import EncryptionService from "../../../../services/vo/encryption-service";
import UserService from "../../../../services/vo/user-service";

import PasswordChange from "../../../vo/shared-components/password-change/PasswordChange";
import PasswordResetPage from "../../password-reset-page/PasswordResetPage";
import Consent from "../../login-page/consent/consent";

import NewPasswordUpdate from "./new-password-pop-up";
import { useTranslation } from "react-i18next";

const COUNTDOWN_TIME = 30;

const LoginPopUp = (props) => {
  const {
    onCloseOpenLogin,
    isnewuser,
    existinguserId,
    voDetails,
    isExistingUserView,
    findByOtherInfo,
    nextUrl,
    SetMainPageLoading,
    hasUpdatePasswordPaneLoaded,
  } = props;
  const [globalVariable] = useGlobalStore(false);
  const [loading, SetLoading] = useState(false);
  const [forgotPassword, SetForgetPasswordScreen] = useState(false);
  const [resendPassword, SetResendPasswordScreen] = useState(false);
  const [displayEmail, SetDisplayEmail] = useState(false);
  const [displayforgotpasswordError, SetDisplayforgotpasswordError] =
    useState(false);

  const [isLogin, SetIsLogin] = useState(true);
  const [isFirstTimePasswordChanged, SetIsFirstTimePasswordChanged] =
    useState(false);
  const [isPolicyAccepted, SetIsPolicyAccepted] = useState(false);
  const [isPasswordReset, SetIsPasswordReset] = useState(false);
  const [isUpdateNewPassword, SetIsUpdateNewPassword] = useState(false);
  const [countdownTimer, SetcountdownTimer] = useState(COUNTDOWN_TIME);
  const [isCoundownActive, SetIsCoundownActive] = useState(false);
  const [tokenData, SetTokenData] = useState(null);
  const [isExistingByOtherInfo, SetIsExistingByOtherInfo] = useState(false);
  const history = useHistory();
  const {
    handleSubmit,
    register,
    reset,
    errors,
    formState,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const [errorMessage, SetErrorMesssage] = useState(null);
  const [successMessage, SetSuccessMesssage] = useState(false);
  const [isSendMail, SetIsSendMail] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (voDetails != null) {
      SetDisplayEmail(voDetails?.email);
    }
    if (hasUpdatePasswordPaneLoaded) {
      SetIsUpdateNewPassword(true);
      SetIsLogin(false);
      SetLoading(false);
      SetTokenData(AuthService.getTokenInfo());
    }
  }, []);

  useEffect(() => {
    if (isCoundownActive) {
      setTimeout(() => {
        if (countdownTimer > 0) {
          SetcountdownTimer(countdownTimer - 1);
        } else {
          SetResendPasswordScreen(false);
          SetIsCoundownActive(false);
          SetcountdownTimer(COUNTDOWN_TIME);
        }
      }, 1000);
    }
  }, [isCoundownActive, countdownTimer]);

  const onResendPassword = async (e) => {
    e.preventDefault();
    const data = {
      userName: displayEmail,
      languageCode: globalVariable.moduleData?.language,
    };
    const response = await AuthService.resendOtpCode(data);
    if (response?.data != null && response.data.status) {
      SetResendPasswordScreen(true);
      SetIsCoundownActive(true);
    }
  };

  const onDisplayFogotPassword = (e) => {
    e.preventDefault();
    SetForgetPasswordScreen(true);
    SetSuccessMesssage(false);
  };

  const onLoginSubmit = async (values) => {
    SetErrorMesssage(null);
    try {
      SetLoading(true);
      const data = {
        username: isnewuser
          ? displayEmail
          : existinguserId != null
          ? existinguserId
          : values.username,
        password: values.password,
        grant_type: "password",
        client_id: "AutoconceptWeb",
        culture_code: globalVariable.moduleData.language,
        user_type: 3,
      };
      const response = await AuthService.loginNewCustomer(data, isnewuser);
      response.data.cultureCode = globalVariable.moduleData.language;
      response.data.loginflow =
        existinguserId != null ? "LoginPopup" : "NewUser";
      if (isExistingUserView && !isnewuser) {
        sessionStorage.removeItem("vo-details");
      }
      if (isnewuser) {
        // Need to show the Password
        var mappedData = mapVoDetailstoUpdate(
          voDetails,
          response?.data?.isCompany
        );
        await UserService.UpdateUserDetailsWithOTP(
          mappedData,
          response?.data?.access_token
        );
        SetIsUpdateNewPassword(true);
        SetTokenData(response.data);
        SetIsLogin(false);
        SetLoading(false);
      } else {
        if (
          response.data.isFirstTimePasswordChanged != "True" ||
          response.data.isPolicyAccepted != "True"
        ) {
          SetIsUpdateNewPassword(true);
          SetTokenData(response.data);
          SetIsLogin(false);
          SetLoading(false);
        } else {
          // New pass word Update page redirection
          await AuthService.storeTokenInfo(response.data);
          if (existinguserId != null) {
            history.push(nextUrl);
          } else {
            // window.location.reload();
            onCloseOpenLogin();
            history.push(nextUrl);
          }
          // commented and taket up
          // await AuthService.storeTokenInfo(response.data);
        }
      }

      // if ((response.data.isFirstTimePasswordChanged == "True") && (response.data.isPolicyAccepted == "True")) {

      // }
      // else {
      //     SetTokenData(response.data);
      //     SetLoading(false)
      //     SetIsLogin(false);
      //     SetIsFirstTimePasswordChanged(response.data.isFirstTimePasswordChanged=="True");
      //     SetIsPolicyAccepted(response.data.isPolicyAccepted=="True");
      // }
    } catch (error) {
      SetLoading(false);
      if (error.response.data != null) {
        if (isnewuser) {
          if (error.response.data.error == "inactive_customer") {
            SetErrorMesssage(t("login_newuser_inactive_state"));
          } else {
            SetErrorMesssage(globalVariable.moduleData.login_error_message);
          }
        } else {
          if (error.response.data.error == "inactive_customer") {
            SetErrorMesssage(t("login_newuser_inactive_state"));
          } else {
            SetErrorMesssage(t("login_newuser_invalid_credentials"));
          }
        }
      }
    }
  };

  const mapVoDetailstoUpdate = (userData, isCompany) => {
    const profile = {};
    profile.email = userData?.email;
    profile.city = userData?.city;
    profile.zipCode = userData?.postcode;
    profile.address = userData?.address;
    profile.lastName = userData?.surname;
    profile.personalnumber = userData?.personalnumber;
    profile.mobile = userData?.mobile_number;
    if (isCompany == "False") {
      profile.firstName = userData?.firstname;
    }
    return profile;
  };

  const onForgotPasswordSubmit = async (values) => {
    SetErrorMesssage(null);
    try {
      SetDisplayforgotpasswordError(false);
      SetIsSendMail(false);
      SetSuccessMesssage(false);
      SetLoading(true);
      const data = {
        userName: existinguserId != null ? existinguserId : values.username,
        languageCode: globalVariable.moduleData.language,
      };
      const response = await AuthService.resetPassword(data);
      if (response?.data != null && response.data.data.status) {
        SetSuccessMesssage(true);
        SetIsSendMail(true);
      } else {
        SetIsSendMail(false);
        SetSuccessMesssage(false);
        SetDisplayforgotpasswordError(true);
      }
      SetLoading(false);
    } catch (error) {
      SetIsSendMail(false);
      SetSuccessMesssage(false);
      SetLoading(false);
      if (error != null) {
        SetErrorMesssage(globalVariable.moduleData.error_message);
      }
    }
  };

  const onPasswordChange = () => {
    tokenData.isFirstTimePasswordChanged = "True";
    tokenData.isPolicyAccepted = "True";
    AuthService.storeTokenInfo(tokenData);
    SetIsFirstTimePasswordChanged(true);
    CheckAuthFlow();
  };

  const onPolicyAccepted = () => {
    tokenData.isPolicyAccepted = "True";
    AuthService.storeTokenInfo(tokenData);
    SetIsPolicyAccepted(true);
    CheckAuthFlow();
  };

  const CheckAuthFlow = () => {
    SetLoading(true);
    const isAuthenticated = AuthService.isAuthorized();
    if (isAuthenticated) {
      const currentToken = AuthService.getTokenInfo();
      if (
        currentToken?.isFirstTimePasswordChanged == "True" &&
        currentToken?.isPolicyAccepted == "True"
      ) {
        onCloseOpenLogin();
      } else {
        SetIsPolicyAccepted(currentToken.isPolicyAccepted == "True");
        SetTokenData(currentToken);
        SetIsFirstTimePasswordChanged(
          currentToken.isFirstTimePasswordChanged == "True"
        );
        SetIsLogin(false);
        SetLoading(false);
      }
    } else {
      SetLoading(false);
    }
  };

  const onUpdateNewPassword = async (values) => {
    const data = {
      newPassword: values.newpassword,
    };
    SetLoading(true);
    const response = await AuthService.UpdateNewPassword(
      data,
      tokenData?.access_token
    );
    if (response.data != null) {
      SetLoading(false);
      onCloseOpenLogin();
      if (window.location.pathname.split("/").slice(1)[0] == "information") {
        let nextPageUrl = "checkout" + window.location.search.split("/")[0];
        window.location.assign(nextPageUrl);
      }
    }
  };

  const onDisplayWantToChangeEmail = () => {
    onCloseOpenLogin();
  };

  return (
    <div id="popup1" class="overlay login-popup">
      <div class="popup">
        {isSendMail ? (
          <a
            class="close"
            href="javascript: void(0)"
            onClick={() => {
              SetIsSendMail(false), SetForgetPasswordScreen(false);
            }}
          >
            &times;
          </a>
        ) : (
          <a
            class="close"
            href="javascript: void(0)"
            onClick={onCloseOpenLogin}
          >
            &times;
          </a>
        )}
        {isSendMail ? (
          <div class="content">
            <div class="login-popup-wrap p-20 pt-30">
              <div class="login-popup-body mb-20">
                {successMessage && (
                  <span className="success">
                    {t("password_reset_success_msg")}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div class="content">
            <div class="login-popup-wrap p-20 pt-30">
              {loading ? <Loading></Loading> : ""}

              {/* Forgot Password popup content NC-3 */}
              {forgotPassword ? (
                <div class="login-popup-body mb-20">
                  <h2>{t("forgot_password_title")}</h2>
                  <h4>{t("forgot_password_sub_title")}</h4>
                  <div className="">
                    {successMessage != null ? (
                      <div className="success">{successMessage}</div>
                    ) : (
                      ""
                    )}

                    <form onSubmit={handleSubmit(onForgotPasswordSubmit)}>
                      <div class="flex column mb-10">
                        <label class="input-label mb-5">
                          {t("login_Username")}
                        </label>
                        {existinguserId != null ? (
                          <input
                            class="input-style-2"
                            name="username"
                            value={existinguserId}
                            disabled
                            type="text"
                            ref={register({
                              required: t("login_popup_required_text"),
                            })}
                          />
                        ) : (
                          <input
                            class="input-style-2"
                            name="username"
                            type="text"
                            ref={register({
                              required: t("login_popup_required_text"),
                            })}
                          />
                        )}
                        <span className="error">
                          {" "}
                          {errors.username && errors.username.message}
                        </span>
                        {displayforgotpasswordError ? (
                          <span className="error">
                            {" "}
                            {t("forgot_password_invalid_username")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="flex column mb-20">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg"
                        >
                          {t("forgot_password_send_newpassword")}
                        </button>
                      </div>
                    </form>
                    <div class="flex column mb-20 forgot-pass">
                      <p>
                        {t("forgot_password_bottom_message")}{" "}
                        <a href={t("customer_support_link")} target="_blank">
                          {t("forgot_password_customersupport")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {!forgotPassword ? (
                <div class="login-popup-body mb-20">
                  {isUpdateNewPassword ? (
                    <div>
                      <h2>{t("new_password_popup_heading")}</h2>
                      <h4>{t("new_password_popup_sub_heading")}</h4>
                    </div>
                  ) : isnewuser && !isExistingUserView ? (
                    <div>
                      <h2>{t("login_newuser_emailmessage")}</h2>
                      <h3>{displayEmail}</h3>
                      <h4>
                        {globalVariable.moduleData?.login_newuser_message}
                      </h4>
                    </div>
                  ) : (
                    ""
                  )}

                  {findByOtherInfo ? (
                    <div>
                      <h2>
                        {t("login_existinguser_emailmessage")}{" "}
                        {t("login_existinguser_customer_info")}
                      </h2>
                      {/* <h3>{t("login_existinguser_customer_info")}</h3> */}
                      <h4>{t("login_existinguser_proceed")}</h4>
                    </div>
                  ) : !isnewuser &&
                    !isExistingUserView &&
                    !isUpdateNewPassword ? (
                    <div>
                      <h2>{t("login_existinguser_emailmessage")}</h2>
                      <h3>{displayEmail}</h3>
                      <h4>{t("login_existinguser_proceed")}</h4>
                    </div>
                  ) : (
                    ""
                  )}

                  {isExistingUserView && !isnewuser && !isUpdateNewPassword ? (
                    <div>
                      <h2>{t("login_existinguser_title")}</h2>
                      <h4>{t("login_existinguser_sub_title")}</h4>
                    </div>
                  ) : (
                    ""
                  )}

                  {
                    /** Login  Pop up*/
                    isLogin ? (
                      <div className="">
                        <form onSubmit={handleSubmit(onLoginSubmit)}>
                          {!isnewuser ? (
                            <div class="flex column mb-10">
                              <label class="input-label mb-5">
                                {t("login_Username")}
                              </label>
                              {existinguserId != null ? (
                                <input
                                  class="input-style-2"
                                  name="username"
                                  value={existinguserId}
                                  disabled
                                  type="text"
                                  ref={register({
                                    required: t("login_popup_required_text"),
                                  })}
                                />
                              ) : (
                                <input
                                  class="input-style-2"
                                  name="username"
                                  type="text"
                                  ref={register({
                                    required: t("login_popup_required_text"),
                                  })}
                                />
                              )}
                              <span className="error">
                                {" "}
                                {errors.username && errors.username.message}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}

                          <div class="flex column mb-20">
                            {!isnewuser ? (
                              <label class="input-label mb-5">
                                {t("login_existinguser_password")}
                              </label>
                            ) : (
                              <label class="input-label mb-5">
                                {t("login_existinguser_code")}
                              </label>
                            )}
                            <input
                              class="input-style-2"
                              name="password"
                              type="password"
                              ref={register({
                                required: t("login_popup_required_text"),
                              })}
                            />
                            <span className="error">
                              {" "}
                              {errors.password && errors.password.message}
                            </span>
                          </div>
                          {errorMessage != null ? (
                            <div class="flex column mb-5">
                              <span className="error"> {errorMessage}</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div class="flex column mb-20">
                            <button
                              type="submit"
                              className={
                                formState.isValid
                                  ? "btn btn-primary btn-lg"
                                  : "btn btn-disable btn-lg"
                              }
                              disabled={!formState.isValid}
                            >
                              {t("login_existinguser_login_button")}
                            </button>
                          </div>

                          {!(isnewuser && !isExistingUserView) && (
                            <div class="flex column mb-20 forgot-pass">
                              <p>
                                {t("forgot_password_title")}
                                <a
                                  href="javascript:void(0)"
                                  onClick={(e) => onDisplayFogotPassword(e)}
                                >
                                  {t("login_existinguser_clickhere")}
                                </a>
                              </p>
                            </div>
                          )}
                        </form>

                        {isnewuser && !isExistingUserView && (
                          <div class="flex column mb-30 forgot-pass">
                            {!resendPassword ? (
                              <p>
                                {
                                  globalVariable.moduleData
                                    ?.login_existinguser_code_message
                                }
                                <a
                                  href="javascript: void(0)"
                                  onClick={(e) => onResendPassword(e)}
                                >
                                  {
                                    globalVariable.moduleData
                                      ?.login_existinguser_resend
                                  }
                                </a>
                              </p>
                            ) : (
                              <div>
                                <p class="mt-0">
                                  {
                                    globalVariable.moduleData
                                      ?.login_existinguser_successfull
                                  }{" "}
                                  {countdownTimer}{" "}
                                  {
                                    globalVariable.moduleData
                                      ?.login_existinguser_seconds
                                  }{" "}
                                </p>
                              </div>
                            )}
                            <p>
                              {
                                globalVariable.moduleData
                                  ?.login_existinguser_change_email_message
                              }
                              <a
                                href="javascript: void(0)"
                                onClick={onCloseOpenLogin}
                              >
                                {
                                  globalVariable.moduleData
                                    ?.login_existinguser_change_email_link
                                }
                              </a>
                            </p>
                          </div>
                        )}
                        <div class="flex column mb-30 forgot-pass message">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t("login_popup_contact_message"),
                            }}
                          />
                        </div>
                      </div>
                    ) : isUpdateNewPassword ? (
                      <NewPasswordUpdate
                        onDisplayWantToChangeEmail={onDisplayWantToChangeEmail}
                        onUpdateNewPassword={onUpdateNewPassword}
                      ></NewPasswordUpdate>
                    ) : (
                      //  !isPolicyAccepted ? <Consent onPolicyAccepted={onPolicyAccepted} tokenData={tokenData}></Consent>
                      // : !isFirstTimePasswordChanged ? <PasswordChange onPasswordChange={onPasswordChange} tokenData={tokenData}></PasswordChange>
                      // :
                      ""
                    )
                  }
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPopUp;
