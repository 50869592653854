import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//local
import CurrentCoverageInfo from "../renewal-components/current-coverage-info/CurrentCoverageInfo";
import CheckoutPanal from "../checkout-page/checkout-panal/CheckoutPanal";
import ProductCart from "../renewal-components/product-cart/ProductCart";
import PageStepper from "../renewal-components/page-stepper/PageStepper";
import PriceMeter from "../renewal-components/price-meter/PriceMeter";
import Loading from "../shared-components/loading/Loading";

//services
import UserService from "../../../services/vo/user-service";
import CartService from "../../../services/vo/add-to-cart-service";
import AuthService from "../../../services/vo/auth-service";
import InsuranceService from "../../../services/vo/insurance-service";

//globle state
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";

//utils
import AuthUtils from "../../../utils/auth-utils/AuthUtils";
import SessionStorageUtills from "../../../utils/auth-utils/SessionStorageUtills";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../utils/title-utils/TabTitle";

function CheckoutPage(props) {
  const renewalFlow = ["product", "owner_details", "review", "confirmation"];
  const [globalVariable, globalVariabledispatch] = useGlobalStore(false);
  const [currentStage, setCurrentStage] = useState(3);
  const [urlParams, setUrlParams] = useState();
  const [backUrl, SetBackUrl] = useState("/");
  const [noProductAvailable, setNoProductAvailable] = useState(0);
  const [loading, SetLoading] = useState(true);
  const [isAnotherMBIAvailable, SetIsAnotherMBIAvailable] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  useTitle(t("checkout_page_tab_title"));

  useEffect(() => {
    SetLoading(true);
    if (status != 0) {
      AuthService.matchCultureAndRedirectURL();
    }
    if (
      AuthUtils.CheckAuthFlowCompleted(
        globalVariable.moduleData.login_page_url,
        globalVariable.moduleData.profile_page_url
      )
    ) {
      const params = new URLSearchParams(window.location.search);
      const vehicleNo = atob(params.get("vehicle_no"));
      const mileage = atob(params.get("mileage"));
      const status = atob(params.get("status"));
      setUrlParams(params);
      SetBackUrl(globalVariable.moduleData.information_page_url + "?" + params);
      ValidateSavedData(vehicleNo, mileage, status);

      if (status == 0) {
        CheckValidPurchase(vehicleNo);
      }
      SetLoading(false);
    }
  }, []);

  const CheckValidPurchase = async (regno) => {
    const result = await InsuranceService.CheckMultipleMBIExist(regno);
    SetIsAnotherMBIAvailable(result?.data?.isExist == true);
  };

  const RestrictContinuation = () => {
    setNoProductAvailable(true);
  };

  const ValidateSavedData = (regNo, mileage, status) => {
    const cartData = CartService.getAllfromCart();
    if (cartData?.length > 0) {
      if (
        status == 0 &&
        !SessionStorageUtills.ValidateCartData(
          cartData,
          globalVariable?.moduleData?.language
        )
      ) {
        CartService.removeAllFromCart();
      }
    }
    const previousData = JSON.parse(sessionStorage.getItem("vo-info"));
    if (previousData) {
      if (
        status == 0 &&
        !SessionStorageUtills.IsSessionValid(previousData?.createDate)
      ) {
        SetLoading(true);
        history.push(globalVariable.moduleData.new_vehicle_page_url);
      } else if (
        previousData.regNo !== regNo ||
        previousData.mileage !== mileage
      ) {
        CartService.removeAllFromCart();
        sessionStorage.removeItem("vo-info");
        sessionStorage.removeItem("currentCoverage");
        sessionStorage.removeItem("vo-details");
        sessionStorage.removeItem("DibsData");

        const voinfo = { regNo: regNo, mileage: mileage };
        sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
      }
    } else {
      const voinfo = { regNo: regNo, mileage: mileage };
      sessionStorage.setItem("vo-info", JSON.stringify(voinfo));
    }
  };

  const onBackClicked = () => {
    SetLoading(true);
    AuthUtils.CheckEmailFieldEditable();
    history.push(backUrl);
  };

  return (
    <div>
      {loading ? (
        <Loading></Loading>
      ) : (
        <div class="page-main-wrapper">
          {isAnotherMBIAvailable ? (
            <div class="alert mt-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: t("checkout_multipleMBI_alert"),
                }}
              />
            </div>
          ) : (
            ""
          )}
          <section class="flex space-between wrap">
            <PageStepper
              isThisStageComplete={true}
              SetLoading={SetLoading}
              urlParams={urlParams}
              disable={noProductAvailable}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
            ></PageStepper>
            <PriceMeter></PriceMeter>
          </section>
          <section class="vehicle-summary-wrap mob-block">
            <CurrentCoverageInfo></CurrentCoverageInfo>
            <div style={{ visibility: "hidden" }}>
              <ProductCart></ProductCart>
            </div>
          </section>
          <CheckoutPanal
            onClickBack={onBackClicked}
            isAnotherMBIAvailable={isAnotherMBIAvailable}
            setStage={setCurrentStage}
            setBackUrl={backUrl}
          ></CheckoutPanal>
        </div>
      )}
    </div>
  );
}

export default CheckoutPage;
