import React, { useState, useEffect } from "react";
import { useGlobalStore } from "../../../utils/global-store/vo/Vo-globalStore";
import i18n from "../../../services/i18n";
//service
import CartService from "../../../services/vo/add-to-cart-service";
//css
import "./PaymentCancel.scss";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../utils/title-utils/TabTitle";
import { GACustomPageView } from "../../../utils/ga-utils/ga-utils";

function PaymentCancel(props) {
  const [globalVariable] = useGlobalStore(false);

  useEffect(() => {
    var sessionLanguage = sessionStorage.getItem("VpLang");
    i18n.changeLanguage(sessionLanguage);

    sessionStorage.removeItem("vo-details");

    GACustomPageView("payment_cancel", "Payment Cancel");
  }, []);

  const { t } = useTranslation();

  useTitle(t("paymnet_cancel_page_tab_title"));

  const onHomeClick = () => {
    const originUrl = sessionStorage.getItem("originUrl");
    const lang = sessionStorage.getItem("VpLang");
    if (originUrl != "null") {
      window.location.assign(atob(originUrl));
    } else {
      window.location.href = `https://www.autoconcept.${lang}/home`;
    }
  };

  return (
    <div class="page-main-wrapper">
      <section class="thankyou-wrapper mt-10">
        <div class="confirm-icon-background mb-20">
          <div class="confrim-icon"></div>
        </div>
        <div class="content-wrap">
          <h1 class="mb-5 light-red">{t("payment_cancel_header")}</h1>
          <p class="mb-20">{t("payment_cancel_header_detailtext")}</p>
          <div className="buttons-wrapper mt-20 mb-40 flex">
            <button
              type="button"
              class="btn-primary mr-10"
              onClick={() => onHomeClick()}
            >
              {t("thankyou_message_backtohome")}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentCancel;
