import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import SelectedOrdersTable from "../../renewal-components/selected-orders-table/SelectedOrdersTable";
//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";
import Loading from "../../shared-components/loading/Loading";
import { useTranslation } from "react-i18next";

//services
import addtoCartService from "../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../services/vo/auth-service";
import insuranceService from "../../../../services/vo/insurance-service";
import EncryptionService from "../../../../services/vo/encryption-service";
import UtilService from "../../../../services/vo/util-service";
import { GACustomEventTrack } from "../../../../utils/ga-utils/ga-utils";

//utils
import DateUtils from "../../../../utils/date-utils/DateFormater";

//Images
import dibsPaymentvisa from "../../../../images/dibs_visa.jpg";
import dibsPaymentnets from "../../../../images/dibs_nets.jpg";

function CheckoutPanal(props) {
  const history = useHistory();
  const [globalVariable] = useGlobalStore(false);
  const [voDetails, SetVoDetails] = useState(null);
  const [selectedPaymentPeriod, SetPaymentPeriod] = useState(null);
  const [selectedPaymentMethod, SetPaymentMethod] = useState(null);
  const [paymentMethodList, SetPaymentsList] = useState();
  const [selectedProductPeriod, SetSelectedProductPeriod] = useState(null);
  const [isNewCustomer, SetIsNewCustomer] = useState(false);
  const [userFlowStatus, SetUserFlowStatus] = useState();
  const [isSelectedpaymentmethod, SetisSelectedpaymentmethod] = useState(false);
  const [personalNumberText, SetpersonalNumberText] = useState(null);
  const [Error, SetError] = useState(false);
  const [isOverlappingItemsAvailable, SetIsOverlappingItemsAvailable] =
    useState(false);
  const { t } = useTranslation();

  const { handleSubmit, register, errors, formState, setValue, reset } =
    useForm({
      mode: "onChange",
      reValidateMode: "onChange",
    });
  const { onClickBack, isAnotherMBIAvailable, setStage, setBackUrl } = props;
  const [loading, SetLoading] = useState(false);

  useEffect(() => {
    sessionStorage.removeItem("DibsData");
    const params = new URLSearchParams(window.location.search);
    const status = params.get("status") ? atob(params.get("status")) : null;
    //status=2 - key protect &  status=3 - Extra  &  status=0 - New purchase flow
    SetUserFlowStatus(status);
    if (status == 0) {
      SetIsNewCustomer(true);
    }
    const getSelectedItem = addtoCartService.getAllfromCart();
    var selectedData = [...getSelectedItem];
    if (selectedData != null && selectedData.length > 0) {
      SetSelectedProductPeriod(selectedData[0].selectedperiod?.period);
      if (selectedData[0].selectedperiod?.price <= 0) {
        SetError(true);
      }
    }
    const GetVoDetails = async () => {
      const storedVoDetails = JSON.parse(sessionStorage.getItem("vo-details"));
      if (storedVoDetails) {
        try {
          const decryptedData = await EncryptionService.DecryptVoDetails(
            storedVoDetails.token
          );
          //decryptedData?.data={} if token expired
          if (
            decryptedData &&
            Object.entries(decryptedData?.data).length !== 0
          ) {
            SetVoDetails(decryptedData.data);
          } else {
            sessionStorage.removeItem("vo-details");
          }
        } catch (error) {
          console.log(error);
          sessionStorage.removeItem("vo-details");
        }
      }
    };
    GetVoDetails();
  }, []);

  const onChangePaymentPeriod = (e) => {
    e.preventDefault();
    if (e.target.value) {
      SetPaymentPeriod(e.target.value);
    }
    setValue(e.target.name, e.target.value, true);
    if (e.target.value === "PerMonth") {
      if (globalVariable?.moduleData?.language == "sv-SE") {
        const paymentMethods = [
          {
            value: "Autogiro",
            text: t("checkoutpage_payment_method_autogiro"),
          },
          {
            value: "cardpayment",
            text: t("checkoutpage_payment_method_kort"),
          },
        ];
        SetPaymentsList(paymentMethods);
      } else {
        const paymentMethods = [
          {
            value: "cardpayment",
            text: t("checkoutpage_payment_method_kort"),
          },
        ];
        SetPaymentsList(paymentMethods);
      }
    } else if (e.target.value === "12Month") {
      const paymentMethods = [
        {
          value: "invoice",
          text: t("checkoutpage_payment_method_faktura"),
        },
        {
          value: "cardpayment",
          text: t("checkoutpage_payment_method_Kortbetalning"),
        },
      ];
      SetPaymentsList(paymentMethods);
    } else {
      SetPaymentsList([]);
      SetPaymentPeriod(null);
    }
    SetisSelectedpaymentmethod(false);
    SetPaymentMethod(null);
    setValue("paymentMethod", null, true);
  };

  const onChangePaymentMethod = (e) => {
    e.preventDefault();
    if (e.target.value) {
      SetisSelectedpaymentmethod(true);
      SetPaymentMethod(e.target.value);
      if (e.target.value == "Autogiro") {
        //var voDetails = JSON.parse(sessionStorage.getItem('vo-details'));
        SetpersonalNumberText(voDetails?.personalnumber);
      }
    } else {
      SetisSelectedpaymentmethod(false);
      SetPaymentMethod(e.target.value);
    }
  };

  const onCheckOut = async (values) => {
    try {
      SetLoading(true);
      const getSelectedItems = addtoCartService.getAllfromCart();
      var selectedItems = [...getSelectedItems];
      if (selectedItems == null && selectedItems.length == 0) {
        return;
      }
      if (values.paymentMethod != null && values.paymentMethod != "") {
        if (values.paymentMethod != "cardpayment") {
          var response = await insuranceService.AddInsuranceList(
            GetpaymentObjectMultiplePurchase(values, selectedItems)
          );

          if (response.data?.success) {
            history.push(globalVariable.moduleData.payment_success_url);
          } else {
            history.push(globalVariable.moduleData.payment_failed_url);
          }
        } else {
          const response = await insuranceService.AddCreditCardPaymentRequest(
            GetpaymentObjectMultiplePurchase(values, selectedItems)
          );
          if (response.data != null) {
            SetLoading(true);
            sessionStorage.removeItem("DibsData");
            const dibsData = {
              DibsOrderId: response.data,
              DibsRequestType: isNewCustomer ? "NEWPAYMENT" : "RENEWAL",
              createdAt: new Date(),
            };
            sessionStorage.setItem("DibsData", JSON.stringify(dibsData));

            var vehicleData = JSON.parse(
              sessionStorage.getItem("currentCoverage")
            );
            //Dibs Path
            //await insuranceService.GetRenewalInsuranceDetails(
            //  response.data,
            //  UtilService.getVendorId(),
            //  vehicleData?.regNo
            //);
            //Dibs Path
            // await insuranceService.GetRenewalInsuranceDetails(
            //   response.data,
            //   UtilService.getVendorId(),vehicleData?.regNo
            // );

            //Nets Path
            const netsResp = await insuranceService.CreateNetsPayment(
              response.data,
              UtilService.getVendorId()
            );
            window.location = JSON.parse(netsResp.data).hostedPaymentPageUrl;
            //Nets Path End

            SetLoading(false);
          } else {
            window.location.replace(
              globalVariable.moduleData.payment_failed_url
            );
            SetLoading(false);
          }
        }
      }
      GACustomEventTrack("click_checkout_button", "Click Checkout Button");
    } catch (error) {
      console.log(error);
      SetLoading(false);
    }
  };

  const GetpaymentObjectMultiplePurchase = (objVal, selectedItems) => {
    var vehicleData = JSON.parse(sessionStorage.getItem("currentCoverage"));
    // var voDetails = JSON.parse(sessionStorage.getItem('vo-details'));
    var isPermonth = false;
    var accountNo = null;
    var cleaingNo = null;
    var PaymentType = null;
    let externalEmail = null;
    if (objVal.paymentPeriod == "PerMonth") {
      isPermonth = true;
      accountNo = objVal?.kontonr;
      cleaingNo = objVal?.clearingNo;
    }

    if (objVal.paymentMethod == "invoice") {
      PaymentType = "Invoice";
    } else if (objVal.paymentMethod == "Autogiro") {
      PaymentType = "DirectDebit";
    } else if (objVal.paymentMethod == "cardpayment") {
      PaymentType = "CreditCard";
    } else if (
      objVal.paymentMethod == "cardpayment" &&
      objVal.paymentPeriod == "PerMonth"
    ) {
      PaymentType = "CreditCardPerMonth";
    }

    if (sessionStorage.getItem("externalReferralEmail")) {
      externalEmail = sessionStorage.getItem("externalReferralEmail");
    }

    const data = {
      arrivalDate: new Date(),
      IsPerMonthPayment: isPermonth,
      PaymentType: PaymentType,
      regNo: vehicleData?.regNo,
      mileage: vehicleData?.mileage,
      clearingNo: cleaingNo,
      accountNo: accountNo,
      tempOfferId: "1",
      policyId: null,
      encUser: "1",
      encPass: "1",
      enginePower: isNewCustomer
        ? vehicleData.enginePower
        : vehicleData?.ActiveInsurance?.enginePower,
      countryId: "6",
      customerId: "",
      vendorId: UtilService.getVendorId(),
      langCode: globalVariable?.moduleData?.language,
      buyerOwnerDetails: {
        PersonNo: voDetails?.personalnumber,
        firstName: voDetails?.firstname,
        dob: voDetails?.dob,
        orgOrLastName: voDetails?.surname,
        address: voDetails?.address,
        zipCode: voDetails?.postcode,
        city: voDetails?.city,
        phone: voDetails?.mobile_number,
        email: voDetails?.email,
        payerPersonNo: voDetails?.personalnumber,
        IsCompany: "1",
      },
      singleOfferList: GetSelectedItemList(selectedItems, vehicleData),
      externalRefferalEmail: externalEmail,
    };
    return data;
  };

  const GetSelectedItemList = (selectedItems, vehicleData) => {
    if (selectedItems) {
      var productList = [];
      selectedItems.forEach((item) => {
        var product = {};
        (product.insuranceId =
          isNewCustomer || item.type != "MBI"
            ? null
            : userFlowStatus == 2
            ? vehicleData?.insuranceId
            : vehicleData?.ActiveInsurance?.insuranceId),
          (product.insurancePeriod = item.selectedperiod?.period),
          (product.productId = item.id),
          (product.startDate = DateUtils.FormatDate(new Date(item.startDate))),
          (product.endDate = new Date(
            getEndDate(item.startDate, item.selectedperiod?.period)
          ));

        if (item.type == "MBI") {
          if (productList.length > 0) {
            const firstItem = productList[0];
            productList[0] = product;
            productList.push(firstItem);
          } else {
            productList.push(product);
          }
        } else {
          productList.push(product);
        }
      });

      return productList;
    } else {
      return null;
    }
  };

  const GetPaymentObject = (objVal, selectedItems) => {
    var vehicleData = JSON.parse(sessionStorage.getItem("currentCoverage"));
    // var voDetails = JSON.parse(sessionStorage.getItem('vo-details'));
    var isPermonth = false;
    var accountNo = null;
    var cleaingNo = null;
    var PaymentType = null;
    if (objVal.paymentPeriod == "PerMonth") {
      isPermonth = true;
      accountNo = objVal?.kontonr;
      cleaingNo = objVal?.clearingNo;
    }

    if (objVal.paymentMethod == "invoice") {
      PaymentType = "Invoice";
    } else if (objVal.paymentMethod == "Autogiro") {
      PaymentType = "DirectDebit";
    } else if (objVal.paymentMethod == "cardpayment") {
      PaymentType = "CreditCard";
    } else if (
      objVal.paymentMethod == "cardpayment" &&
      objVal.paymentPeriod == "PerMonth"
    ) {
      PaymentType = "CreditCardPerMonth";
    }
    const data = {
      productId: selectedItems[0].id,
      arrivalDate: new Date(),
      langCode: isNewCustomer
        ? globalVariable?.moduleData?.language
        : vehicleData?.ActiveInsurance.Language,
      period: selectedItems[0].selectedperiod?.period,
      IsPerMonthPayment: isPermonth,
      PaymentType: PaymentType,
      startDate: DateUtils.FormatDate(new Date(selectedItems[0].startDate)),
      endDate: new Date(
        getEndDate(
          selectedItems[0].startDate,
          selectedItems[0].selectedperiod?.period
        )
      ),
      regNo: vehicleData?.regNo,
      mileage: vehicleData?.mileage,
      clearingNo: cleaingNo,
      accountNo: accountNo,
      tempOfferId: "1",
      policyId: null,
      encUser: "1",
      encPass: "1",
      insurancePeriod: selectedItems[0].selectedperiod?.period,
      enginePower: isNewCustomer
        ? vehicleData.enginePower
        : vehicleData?.ActiveInsurance?.enginePower,
      countryId: "6",
      insuranceId: isNewCustomer
        ? null
        : userFlowStatus == 2
        ? vehicleData?.insuranceId
        : vehicleData?.ActiveInsurance.insuranceId,
      customerId: "",
      vendorId: UtilService.getVendorId(),
      buyerOwnerDetails: {
        PersonNo: voDetails?.personalnumber,
        firstName: voDetails?.firstname,
        dob: voDetails?.dob,
        orgOrLastName: voDetails?.surname,
        address: voDetails?.address,
        zipCode: voDetails?.postcode,
        city: voDetails?.city,
        phone: voDetails?.mobile_number,
        email: voDetails?.email,
        payerPersonNo: voDetails?.personalnumber,
        IsCompany: "1",
      },
    };
    return data;
  };

  const getEndDate = (startdate, period) => {
    var start = new Date(startdate);
    var endDate = start.setMonth(start.getMonth() + Number(period));
    return new Date(endDate).toLocaleDateString();
  };

  const onBackClicked = (e) => {
    e.preventDefault();
    onClickBack();
  };

  return (
    <div class="page-main-wrapper">
      {loading ? (
        <Loading></Loading>
      ) : (
        <div>
          <section class="main-page-title mt-10 mb-10">
            <h1>{t("checkoutpage_title")}</h1>
          </section>

          <SelectedOrdersTable
            SetIsOverlappingItemsAvailable={SetIsOverlappingItemsAvailable}
            setStage={setStage}
            setBackUrl={setBackUrl}
            ApplicationStage="review"
          ></SelectedOrdersTable>
          <section class="checkout-form-wrapper mt-10">
            <form onSubmit={handleSubmit(onCheckOut)}>
              {!isAnotherMBIAvailable ? (
                <div>
                  {Error ? (
                    <h3 className="error-msg">
                      {
                        globalVariable?.moduleData
                          ?.checkoutpage_payment_invalid_price_error
                      }
                    </h3>
                  ) : (
                    <div>
                      <div class="form-field payment-period">
                        <label class="input-label-style-2">
                          {t("checkoutpage_payment_period")}
                        </label>
                        <select
                          class="input-style-3"
                          name="paymentPeriod"
                          value={selectedPaymentPeriod}
                          onChange={(e) => onChangePaymentPeriod(e)}
                          ref={register({
                            required: (
                              <div>
                                {t("checkoutpage_payment_period_required")}
                              </div>
                            ),
                          })}
                        >
                          <option value="" disabled selected>
                            {t("checkoutpage_payment_select")}
                          </option>
                          <option value="PerMonth">
                            {t("checkoutpage_payment_monthly")}
                          </option>
                          <option value="12Month">
                            {t("checkoutpage_payment_12months")}
                          </option>
                        </select>
                        <div className="form-validation mt-5">
                          {" "}
                          {errors.paymentPeriod && errors.paymentPeriod.message}
                        </div>
                      </div>
                      <div class="form-field payment-method">
                        <label class="input-label-style-2">
                          {t("checkoutpage_payment_method")}
                        </label>
                        <select
                          class="input-style-3"
                          name="paymentMethod"
                          value={selectedPaymentMethod}
                          onChange={(e) => onChangePaymentMethod(e)}
                          ref={register({
                            required: (
                              <div>
                                {t("checkoutpage_payment_method_required")}
                              </div>
                            ),
                          })}
                        >
                          <option value="" disabled selected>
                            {t("checkoutpage_payment_select")}
                          </option>
                          {paymentMethodList?.map((subitem, subinx) => (
                            <option value={subitem.value}>
                              {subitem.text}
                            </option>
                          ))}
                        </select>
                        <div className="form-validation mt-5">
                          {" "}
                          {errors.paymentMethod && errors.paymentMethod.message}
                        </div>
                      </div>

                      {selectedPaymentMethod ==
                      globalVariable?.moduleData
                        ?.checkoutpage_payment_method_autogiro ? (
                        <div className="payment-info">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "checkoutpage_payment_method_autogiro_text"
                              ),
                            }}
                          />

                          <div className="payment-info-form">
                            <div class="flex column mb-10 mt-10">
                              <label class="input-label mb-5">
                                {t(
                                  "checkoutpage_payment_method_autogiro_personalno"
                                )}
                                <span className="required">*</span>
                              </label>
                              <input
                                class="input-style-2"
                                name="personalnumber"
                                placeholder="YYYYMMDD-XXXX"
                                value={personalNumberText}
                                ref={register({
                                  required: (
                                    <div>
                                      {t(
                                        "checkoutpage_payment_method_autogiro_personalno_required"
                                      )}
                                    </div>
                                  ),
                                })}
                                maxLength="13"
                              />
                              <div className="form-validation mt-5">
                                {" "}
                                {errors.personalnumber &&
                                  errors.personalnumber.message}
                              </div>
                            </div>

                            <div class="flex column mb-10 mt-10">
                              <label class="input-label mb-5">
                                {t(
                                  "checkoutpage_payment_method_autogiro_clearingno"
                                )}
                                <span className="required">*</span>
                              </label>
                              <input
                                class="input-style-2"
                                name="clearingNo"
                                placeholder="Clearing No"
                                ref={register({
                                  required: (
                                    <div>
                                      {t(
                                        "checkoutpage_payment_method_autogiro_clearingno_required"
                                      )}
                                    </div>
                                  ),
                                })}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength="5"
                              />
                              <div className="form-validation mt-5">
                                {" "}
                                {errors.clearingNo && errors.clearingNo.message}
                              </div>
                            </div>

                            <div class="flex column mb-10 mt-10">
                              <label class="input-label mb-5">
                                {t(
                                  "checkoutpage_payment_method_autogiro_Kontonr"
                                )}
                                <span className="required">*</span>
                              </label>
                              <input
                                class="input-style-2"
                                name="kontonr"
                                placeholder="Kontonr No"
                                ref={register({
                                  required: (
                                    <div>
                                      {t(
                                        "checkoutpage_payment_method_autogiro_Kontonr_required"
                                      )}
                                    </div>
                                  ),
                                })}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength="12"
                              />
                              <div className="form-validation mt-5">
                                {" "}
                                {errors.kontonr && errors.kontonr.message}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {selectedPaymentMethod == "cardpayment" &&
                      selectedPaymentPeriod == "PerMonth" ? (
                        <div className="payment-info">
                          <p>{t("checkoutpage_payment_method_kort1_text")}</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {selectedPaymentMethod == "cardpayment" &&
                      selectedPaymentPeriod == "12Month" ? (
                        <div className="payment-info">
                          <p>{t("checkoutpage_payment_method_kort2_text")}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <section className="buttons-wrapper mt-0 mb-20 mb-1 flex space-between mob-block button-panel">
                <div className="align-items-flex-end v-center ml-5 mob-block txt-left m-mb-10 links">
                  {(sessionStorage.getItem("VpLang") == "no" ||
                    sessionStorage.getItem("VpLang") == "se") && (
                    <>
                      <p>
                        <strong>{t("Important_information")}</strong>
                      </p>

                      <p>
                        <a
                          href={t("Pre_and_post_purchase_information_link")}
                          rel="noopener"
                          target="_blank"
                        >
                          {t("Pre_and_post_purchase_information")}
                        </a>
                      </p>
                      {sessionStorage.getItem("VpLang") == "no" && (
                        <p>
                          <a
                            href={t("Terms_Text_link")}
                            rel="noopener"
                            target="_blank"
                          >
                            {t("Terms_Text")}
                          </a>
                        </p>
                      )}
                      <p>
                        <a
                          href={t("Terms_of_Purchase_link")}
                          rel="noopener"
                          target="_blank"
                        >
                          {t("Terms_of_Purchase")}
                        </a>
                      </p>
                    </>
                  )}
                </div>
                <div class="flex v-center mob-block flex-end txt-center checkout-buttons">
                  <a
                    href="javacript: void(0)"
                    class="btn-back mr-20"
                    onClick={(e) => onBackClicked(e)}
                  >
                    {t("checkout_back_text")}
                  </a>
                  {!loading && !Error && !isOverlappingItemsAvailable ? (
                    <button
                      type="submit"
                      class="btn-disable"
                      className={
                        formState.isValid && isSelectedpaymentmethod
                          ? "btn-primary"
                          : "btn-disable"
                      }
                      disabled={
                        !formState.isValid || loading | !isSelectedpaymentmethod
                      }
                    >
                      {t("checkout_purchasenow_text")}
                    </button>
                  ) : (
                    <button
                      type="button"
                      class="btn-disable"
                      className="btn-disable"
                    >
                      {t("checkout_purchasenow_text")}
                    </button>
                  )}
                </div>
              </section>
              {selectedPaymentMethod == "cardpayment" ? (
                <div className="buttons-wrapper mt-20 mb-20 flex h-center dibspayment">
                  <div>
                    <img src={dibsPaymentnets} />
                  </div>
                  <div>
                    <img src={dibsPaymentvisa} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </section>
        </div>
      )}
    </div>
  );
}

export default CheckoutPanal;
